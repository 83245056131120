/* eslint-disable react/no-danger */

import React from "react";
import tw, { css } from "twin.macro";
import { graphql, useStaticQuery } from "gatsby";
import { useWindowDimensions } from "~hooks";
import * as A from "~styles/Animations.jsx";
import * as T from "~styles/Typography.jsx";
import { Go, Image } from "~components";
import { getColor } from "~utils/helpers";
import { MEDIA_QUERIES } from "~utils/css";

import { ReactComponent as LogoWhite } from "~assets/svg/logo-white.svg";
import { ReactComponent as LogoRHW } from "~assets/svg/logo-rhw-white.svg";
import { ReactComponent as LogoUNSW } from "~assets/svg/logo-unsw-white.svg";
import { ReactComponent as IconFacebook } from "~assets/svg/facebook.svg";
import { ReactComponent as IconLinkedin } from "~assets/svg/linkedin.svg";
import { ReactComponent as IconYouTube } from "~assets/svg/youtube.svg";

const FooterBodyCopy = ({ children }) => {
  const { screen } = useWindowDimensions();

  if (screen === `xs` || screen === `sm`) {
    return <T.Caption>{children}</T.Caption>;
  }

  return <T.Body font="3">{children}</T.Body>;
};

const Footer = () => {
  const { sanityGlobals } = useStaticQuery(graphql`
    query Footer {
      sanityGlobals {
        footerLinks {
          text
          url
        }
        baseFooterLinks {
          text
          url
        }
        footerPartnerHeading
        footerPartners {
          asset {
            url
          }
          altText
        }
        footerText
        footnote
        footnoteHeading
        socialHeading
        facebookLink
        youTubeLink
        linkedInLink
        copyrightHeading
        copyrightContent
      }
    }
  `);

  //

  return (
    <footer
      css={[
        css`
          transition: transform 0.3s ${A.EASING_CUBIC};

          a {
            ${MEDIA_QUERIES.hoverable} {
              &:hover {
                color: ${getColor(`blue-turq`)};
              }
            }
          }
        `,
        tw`w-full relative block`
      ]}
    >
      <div
        css={[
          tw`w-full relative md:flex pt-16 md:pt-20 px-8 pb-12 bg-black text-white`
        ]}
      >
        <div
          css={[
            css`
              width: 12rem;

              @media screen and (min-width: 1025px) {
                width: 12.5%;
              }
            `,
            tw`relative mb-16`
          ]}
        >
          <Go to="/">
            <LogoWhite css={[tw`w-1/2 relative block`]} />
          </Go>
        </div>

        <div
          css={[
            css`
              border-bottom: 1px solid rgba(255, 255, 255, 0.5);

              @media screen and (min-width: 1025px) {
                width: 30%;
                border-bottom: none;
                padding-right: 8vw;

                p {
                  line-height: 25px;
                }
              }
            `,
            tw`relative mb-8 md:mb-0 pb-5 md:pb-8`
          ]}
        >
          {sanityGlobals?.footerText?.[0] &&
            sanityGlobals?.footerText.map((footerTextItem) => (
              <div css={[tw`mb-4 md:mb-6`]}>
                <FooterBodyCopy>{footerTextItem}</FooterBodyCopy>
              </div>
            ))}
        </div>

        <div
          css={[
            css`
              border-bottom: 1px solid rgba(255, 255, 255, 0.5);

              @media screen and (min-width: 1025px) {
                border-bottom: none;
                width: 22.5%;
              }
            `,
            tw`relative mb-6 md:mb-0 pb-4 md:pb-0 pr-12`
          ]}
        >
          <ul css={[tw`w-full relative -mt-3`]}>
            {sanityGlobals.footerLinks?.[0] &&
              sanityGlobals.footerLinks.map((link) => (
                <li key={link.text} css={[tw`w-full relative block`]}>
                  <Go
                    to={link.url}
                    _css={[
                      tw`relative inline-block pt-1 md:pt-3 pb-1 md:pb-3 font-bold`
                    ]}
                  >
                    <FooterBodyCopy>{link.text}</FooterBodyCopy>
                  </Go>
                </li>
              ))}
          </ul>
        </div>

        <div
          css={[
            css`
              @media screen and (min-width: 1025px) {
                width: 35%;
              }
            `,
            tw`relative`
          ]}
        >
          <div
            css={[
              css`
                border-bottom: 1px solid rgba(255, 255, 255, 0.5);

                @media screen and (min-width: 1025px) {
                  border-bottom: none;
                }
              `
            ]}
          >
            <T.Body _css={[tw`font-bold`]} font="3">
              {sanityGlobals.footerPartnerHeading}
            </T.Body>

            <div
              css={[
                tw`relative flex items-center space-x-10 mt-12 md:mt-8 mb-8 `
              ]}
            >
              {sanityGlobals?.footerPartners?.[0] &&
                sanityGlobals?.footerPartners.map((icon) => (
                  <div key={icon?.asset?.url} css={[tw`w-1/6 relative block`]}>
                    <img
                      css={css`
                        width: 100%;
                        position: relative;
                        display: block;
                      `}
                      src={icon.asset.url}
                      alt={icon.altText}
                    />
                  </div>
                ))}
            </div>
          </div>

          <div css={[tw`relative block mt-8 md:mt-12`]}>
            <T.Body _css={[tw`mb-6 font-bold`]} font="3">
              {sanityGlobals.footnoteHeading}
            </T.Body>

            <FooterBodyCopy>{sanityGlobals.footnote}</FooterBodyCopy>
          </div>

          <div css={[tw`relative block md:flex mt-8 md:mt-12`]}>
            <div css={[tw`w-full md:w-1/2 relative block mb-12 md:mb-0`]}>
              <T.Body _css={[tw`font-bold`]} font="3">
                {sanityGlobals.socialHeading}
              </T.Body>

              <ul
                css={[
                  css`
                    circle {
                      fill: white;
                    }
                  `,
                  tw`w-full relative flex mt-8 md:mt-6`
                ]}
              >
                <li css={[tw`relative block mr-5`]}>
                  <Go to={sanityGlobals.facebookLink}>
                    <IconFacebook
                      fill="black"
                      css={[
                        css`
                          ${MEDIA_QUERIES.hoverable} {
                            &:hover {
                              circle {
                                fill: ${getColor(`blue-turq`)};
                              }
                              path {
                                fill: ${getColor(`white`)};
                              }
                            }
                          }
                        `,
                        tw`w-12`
                      ]}
                    />
                  </Go>
                </li>

                <li css={[tw`relative block mr-5`]}>
                  <Go to={sanityGlobals.youTubeLink}>
                    <IconYouTube
                      fill="black"
                      css={[
                        css`
                          ${MEDIA_QUERIES.hoverable} {
                            &:hover {
                              circle {
                                fill: ${getColor(`blue-turq`)};
                              }
                              path {
                                fill: ${getColor(`white`)};
                              }
                            }
                          }
                        `,
                        tw`w-12`
                      ]}
                    />
                  </Go>
                </li>

                <li css={[tw`relative block mr-5`]}>
                  <Go to={sanityGlobals.linkedInLink}>
                    <IconLinkedin
                      fill="black"
                      css={[
                        css`
                          ${MEDIA_QUERIES.hoverable} {
                            &:hover {
                              circle {
                                fill: ${getColor(`blue-turq`)};
                              }
                              path{
                                fill: ${getColor(`white`)};
                              }
                            }
                          
                        `,
                        tw`w-12`
                      ]}
                    />
                  </Go>
                </li>
              </ul>
            </div>

            <div css={[tw`w-full md:w-1/2 relative block`]}>
              <T.Body _css={[tw`font-bold`]} font="3">
                {sanityGlobals.copyrightHeading}
              </T.Body>

              <T.Body _css={[tw`mt-4`]} font="3">
                {sanityGlobals.copyrightContent}
              </T.Body>
            </div>
          </div>
        </div>
      </div>

      {/* // */}

      <div css={[tw`w-full relative px-8 md:flex bg-blue-faint`]}>
        <div
          css={[
            css`
              @media screen and (min-width: 1025px) {
                width: 12.5%;
              }
            `,
            tw`relative`
          ]}
        />

        <ul
          css={[
            css`
              @media screen and (min-width: 1025px) {
                width: 50%;
                min-width: 600px;
                max-width: 968px;
              }

              a {
                color: ${getColor(`blue-deep`)};

                @media screen and (min-width: 1024px) {
                  color: ${getColor(`black`)};
                }

                ${MEDIA_QUERIES.hoverable} {
                  &:hover {
                    color: ${getColor(`blue-turq`)};
                  }
                }
              }
            `,
            tw`relative md:flex items-center justify-between pt-6 md:pt-2 pb-6 md:pb-3 font-bold text-black`
          ]}
        >
          {sanityGlobals.baseFooterLinks?.[0] &&
            sanityGlobals.baseFooterLinks.map((link) => (
              <li
                key={link.text}
                css={[tw`relative block pt-2 md:pt-0 pb-2 md:pb-0`]}
              >
                <Go to={link.url}>
                  <T.Caption>{link.text}</T.Caption>
                </Go>
              </li>
            ))}
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
